import React from 'react'
import Image from 'gatsby-image'
import {graphql, StaticQuery} from 'gatsby'

import Layout from '../containers/layout'
import SEO from '../components/seo'
import Container from '../components/container'

import BlockContent from '../components/portableText'

import styles from '../components/page.module.css'

export default class Index extends React.Component {
  render () {
    return (

      <StaticQuery
        query={graphql`
            query sanityAboutPage {
              sanityAbout {
                title
                _rawBody
                mainImage {
                  asset {
                    fluid(maxWidth: 700) {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
                sponsorGroup {
                  _key
                  title
                  organization {
                    _key
                    title
                    link
                    image {
                      asset {
                        fluid(maxWidth: 300) {
                          ...GatsbySanityImageFluid
                        }
                      }
                    }
                  }
                }
                team {
                  _key
                  title
                  person {
                    _key
                    firstName
                    lastName
                    role
                    body
                    image{
                      asset {
                        fluid(maxWidth: 500) {
                          ...GatsbySanityImageFluid
                        }
                      }
                    }
                  }
                }
                seoSettings {
                  title
                  description
                }
              }
            }
          `}
        render={data => (
          <>
            {data.sanityAbout.seoSettings && data.sanityAbout.seoSettings.title && data.sanityAbout.seoSettings.description && (<SEO title={data.sanityAbout.seoSettings.title} description={data.sanityAbout.seoSettings.description} />)}

            <Layout Layout mainImage={data.sanityAbout.mainImage}>

              <article className={styles.root} >

                <Container>
                  <div className={styles.pageTitleWrapper}>
                    <h1 className={styles.pageTitle}>{data.sanityAbout.title}</h1>
                  </div>
                  <div>
                    <div className={styles.mainContent}>
                      <div style={{width: '100%'}}>
                        {data.sanityAbout._rawBody && <BlockContent blocks={data.sanityAbout._rawBody} />}

                        {/* Sponsorships and Partnerships */}
                        {data.sanityAbout.sponsorGroup.map(sponsorship => (
                          <div key={sponsorship._key} className={styles.sponsorGroup}>
                            <h3>{sponsorship.title}</h3>
                            <div className={styles.orgGrid}>
                              {sponsorship.organization.map(org => (
                                <div key={org._key}>
                                  {org.link ? (
                                    <a href={org.link} title={org.title} target='_blank' rel='noopener noreferrer'>
                                      <Image fluid={org.image.asset.fluid} alt={org.title} />
                                    </a>
                                  ) : (
                                    <Image fluid={org.image.asset.fluid} alt={org.title} />
                                  )}

                                </div>
                              ))}
                            </div>
                          </div>
                        ))}

                        {/* Team */}
                        <div className={styles.teamWrapper}>

                          {data.sanityAbout.team.map(team => (
                            <div key={team._key} className={styles.teamGroup}>
                              <h3>{team.title}</h3>
                              <div className={styles.teamGrid}>
                                {team.person.map(person => (
                                  <div key={person._key} className={styles.personWrapper}>

                                    <div className={styles.personDetails}>
                                      <h4>{person.firstName} {person.lastName}</h4>
                                      <h5>{person.role}</h5>

                                      {person.image && (
                                        <div className={styles.personPhoto}> <Image fluid={person.image.asset.fluid} alt={person.firstName} /> </div>
                                      )}

                                    </div>

                                    {person.body && (
                                      <div className={styles.bio}>{person.body}</div>
                                    )}

                                  </div>
                                ))}

                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                    </div>
                  </div>
                </Container>

              </article>

            </Layout>
          </>
        )}
      />
    )
  }
}
